import { Body1, H6 } from '$/components/Atoms/Typography';
import GuestLayout from '$/containers/Layout/Guest';
import { Content } from '$/containers/Layout/Guest/styles';
import DefaultLogout from 'remixicon-react/LogoutCircleLineIcon';
import styled from 'styled-components';

export const Container = styled(GuestLayout)`
  width: 100%;

  ${Content} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Circle = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.customTheme.colors.arctic400};
  border-radius: 50%;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-top: ${({ theme }) => theme.customTheme.spacing.lg};
  width: 3.5rem;
`;

export const Icon = styled(DefaultLogout)`
  color: ${({ theme }) => theme.customTheme.colors.night400};
`;

export const Title = styled(H6).attrs({ as: 'h1' })`
  margin-top: ${({ theme }) => theme.customTheme.spacing.sm};
`;
export const Text = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  margin-top: ${({ theme }) => theme.customTheme.spacing.sm};
`;
